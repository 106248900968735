import { z } from 'zod';
export const dateUnits = ['year', 'month', 'week', 'day', 'hour', 'minute'];
export const relativeDataFilterSchema = z.object({
    type: z.literal('relative'),
    amount: z.number(),
    dateUnit: z.enum(dateUnits),
    dir: z.enum(['before', 'after'])
});
export const dateFilterSchema = z.discriminatedUnion('type', [
    relativeDataFilterSchema,
    z.object({
        type: z.literal('absolute'),
        date: z.number()
    }),
    z.object({
        type: z.literal('now')
    })
]);
export const filterSchema = z.discriminatedUnion('type', [
    z.object({
        fieldName: z.string(),
        type: z.literal('dateRange'),
        operation: z.enum(['after', 'before']),
        value: dateFilterSchema
    }),
    z.object({
        fieldName: z.string(),
        type: z.literal('numeric'),
        operation: z.enum(['=', '>', '>=', '<', '<=', '<>']),
        value: z.number()
    }),
    z.object({
        fieldName: z.string(),
        type: z.literal('string'),
        operation: z.enum([
            'starts-with',
            'not-starts-with',
            'ends-with',
            'not-ends-with',
            'contains',
            'not-contains',
            '=',
            '<>'
        ]),
        value: z.string()
    }),
    z.object({
        fieldName: z.string(),
        type: z.literal('category'),
        operation: z.enum(['in', 'not-in']),
        value: z.array(z.string())
    }),
    z.object({
        fieldName: z.string(),
        type: z.literal('empty'),
        operation: z.enum(['empty', 'not-empty'])
    })
]);
const operationSchema = z.enum(['sum', 'avg', 'count', 'min', 'max', 'group_concat']);
export const groupingSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('dateInterval'),
        fieldName: z.string(),
        label: z.string().optional(),
        dateType: z.enum([
            'year',
            'month',
            'week',
            'day',
            'hour',
            'timeOfDay',
            'hourOfDay',
            'dayOfWeek',
            'monthOfYear'
        ]),
        aggregateFields: z.array(z.object({
            fieldName: z.string(),
            operation: operationSchema,
            enabled: z.boolean().optional(),
            label: z.string().optional()
        }))
    }),
    z.object({
        type: z.literal('category'),
        fieldName: z.string(),
        label: z.string().optional(),
        aggregateFields: z.array(z.object({
            fieldName: z.string(),
            operation: operationSchema
        }))
    })
]);
export const getDataSourceConfigLayers = (config) => {
    if ('id' in config) {
        return [
            { sectionId: config.sectionId, filters: config.filters },
            ...(config.grouping ? [{ grouping: config.grouping }] : [])
        ];
    }
    return config.layers;
};
