/**
 * Based on a lexo rank sorting algorithm to minimize updates
 */
export const LexoRankMinChar = 'A'; // The true min, nothing can go above it; creates "deadlock" at the top of a list.
export const LexoRankMaxChar = 'Z'; // This is the max char, but a sort weight can grow indefinitely unless an arbitrary cap is put on it.
export const LexoRankEndWeight = ''; // The character used by the algorithm to mark either end of the character set.
export const LexoRankSafeMinChar = 'B'; // Safe because it still allows sort weights above it
export const LexoRankDefaultChar = 'M'; // A midway default value for entity values providing better distribution.
const gMinChar = LexoRankMinChar.charCodeAt(0);
const gMaxChar = LexoRankMaxChar.charCodeAt(0);
function lexoRank(light, heavy) {
    if (light === LexoRankEndWeight && heavy === LexoRankEndWeight) {
        return String.fromCharCode(gMinChar + 1);
    }
    if (light === heavy)
        return light; // No midpoint to be found!
    if (light === LexoRankEndWeight) {
        light = String.fromCharCode(gMinChar);
    }
    let i = 0;
    let rankl = light.length ? light.charCodeAt(i) : gMinChar;
    let rankh = heavy.length ? heavy.charCodeAt(i) : Math.min(gMaxChar, rankl + 16);
    let mid = Math.floor((rankl + rankh) / 2);
    let rank = '';
    while (rankl === rankh || mid <= rankl) {
        rank += String.fromCharCode(rankl);
        i++;
        rankl = light.length > i ? light.charCodeAt(i) : gMinChar;
        rankh = heavy.length > i ? heavy.charCodeAt(i) : gMaxChar;
        mid = Math.floor((rankl + rankh) / 2);
    }
    while (mid > gMaxChar) {
        rank += String.fromCharCode(gMaxChar);
        mid -= gMaxChar - gMinChar;
    }
    rank += String.fromCharCode(mid);
    if (rank < light || (heavy !== LexoRankEndWeight && rank > heavy)) {
        return light;
    }
    return rank;
}
export function updateSortWeights(original, updatedOrder) {
    // Ids to new sort weights
    const updatedWeights = {};
    const uniqueWeights = new Set();
    const originalOrder = [];
    Object.entries(original).forEach(([id, weight]) => {
        if (!updatedOrder.includes(id)) {
            updatedWeights[id] = null; // Mark specific deletions as null
        }
        else if (weight &&
            !uniqueWeights.has(weight) && // Just in case there was a screw up, don't add duplicate weights
            LexoRankMinChar < weight &&
            weight < LexoRankMaxChar) {
            originalOrder.push(id);
        }
        uniqueWeights.add(weight);
    });
    originalOrder.sort((a, b) => {
        if (original[a] === original[b])
            return 0;
        return original[a] > original[b] ? 1 : -1;
    });
    const origIndices = originalOrder.reduce((indices, id, i) => {
        indices[id] = i;
        return indices;
    }, {});
    const updatedIndices = {};
    const existingIndices = {};
    updatedOrder.forEach((id, i) => {
        updatedIndices[id] = i;
        if (origIndices[id] != null) {
            existingIndices[id] = Object.keys(existingIndices).length;
        }
    });
    let originalIndex = 0;
    let updatedIndex = 0;
    let existingIndex = 0;
    let inserts = [];
    let lastWeight = '';
    function processInserts(high = '') {
        inserts.forEach(id => {
            lastWeight = lexoRank(lastWeight, high);
            updatedWeights[id] = lastWeight;
        });
        inserts = [];
    }
    while (originalIndex < originalOrder.length && updatedIndex < updatedOrder.length) {
        const updatedId = updatedOrder[updatedIndex];
        const originalId = originalOrder[originalIndex];
        // Process any pending inserts and keep iterating
        if (originalId === updatedId) {
            processInserts(original[originalId]);
            updatedIndex++;
            originalIndex++;
            existingIndex++;
            lastWeight = original[originalId];
            continue;
        }
        // Handle new inserts into the middle of the list
        if (origIndices[updatedId] == null) {
            inserts.push(updatedId);
            updatedIndex++;
            continue;
        }
        const relOrigPos = existingIndices[originalId] - existingIndex;
        const relUpdatedPos = origIndices[updatedId] - originalIndex;
        // If the element was swapped, you can swap the weights
        if (relOrigPos - relUpdatedPos === 0) {
            // Both elements were found at a relative distance from eachother, this is a swap
            // Update only the incoming node (current node will get updated when second mismatch is found)
            const currWeight = original[originalId];
            processInserts(currWeight);
            updatedWeights[updatedId] = currWeight;
            updatedIndex++;
            originalIndex++;
            existingIndex++;
            lastWeight = currWeight;
            continue;
        }
        if (relUpdatedPos < 0 || relOrigPos < relUpdatedPos) {
            // The incoming element was moved
            inserts.push(updatedId);
            updatedIndex++;
            existingIndex++;
        }
        else {
            originalIndex++;
        }
    }
    // Handle extra nodes at the bottom of the list as inserts
    while (updatedIndex < updatedOrder.length) {
        inserts.push(updatedOrder[updatedIndex]);
        updatedIndex++;
    }
    processInserts();
    return updatedWeights;
}
