// https://stackoverflow.com/questions/57360205/how-to-convert-a-js-date-programmatically-into-a-google-sheet-serial-number-an
const oneDay = 24 * 60 * 60 * 1000;
export function getDateFromSerial(serial) {
    const intPart = Math.floor(serial);
    const fracPart = serial - intPart;
    const time = Math.round(fracPart * oneDay);
    // Initial date lotus dates were based off of was the one below
    return new Date(+new Date(1899, 11, 30 + intPart) + time);
}
export const getCurrentTimezoneOffsetMilliseconds = () => {
    const date = new Date();
    const localTimeZoneOffsetMinutes = date.getTimezoneOffset();
    return localTimeZoneOffsetMinutes * 60 * 1000;
};
