import { assertNever } from 'axil-utils';
import {
  AbstractDataField,
  AggregateField,
  AllGrouping,
  BaseLayerConfig,
  CategoricalAbstractDataField,
  DataSourceConfigGrouping,
  isCategoricalDataType,
  isDateDataType,
  isNumericAbstractDataField,
  LayerConfig,
  Units
} from 'daydash-data-structures';
import capitalize from 'lodash/capitalize';

export const categoricalCompatibleGrouping = (layers: [BaseLayerConfig, ...LayerConfig[]]) => {
  if (!layers) return false;
  if (layers.length === 0) return false;
  const lastLayer = layers.at(-1) as LayerConfig;
  if (!lastLayer?.grouping) return false;
  return Boolean(
    lastLayer.grouping.type === 'category' ||
      // Allow date interval groupings to be categorical. Day isn't a field type though
      (lastLayer.grouping.type === 'dateInterval' &&
        lastLayer.grouping.dateType !== 'day' &&
        isCategoricalDataType(lastLayer.grouping.dateType))
  );
};
/**
 * TODO:
 *
 * - Think about somehow combining the original label more into the field name
 */
export const getGroupByField = (
  grouping: Exclude<DataSourceConfigGrouping, AllGrouping>,
  initialField: AbstractDataField | null // Allow null for cases where we just want the metadata
): AbstractDataField => {
  if (grouping.type === 'dateInterval') {
    if (grouping.dateType === 'hour') {
      return {
        key: `hour_${grouping.fieldName}`,
        name: `hour_${grouping.fieldName}`,
        label: grouping.label || 'Hour',
        type: 'hour',
        unit: Units.Hour.epochMS
      };
    }
    if (grouping.dateType === 'day') {
      return {
        key: `date_${grouping.fieldName}`,
        name: `date_${grouping.fieldName}`,
        label: grouping.label || 'Date',
        type: 'date',
        unit: Units.Date.epochMS
      };
    }
    if (grouping.dateType === 'week') {
      return {
        key: `week_${grouping.fieldName}`,
        name: `week_${grouping.fieldName}`,
        label: grouping.label || 'Week',
        type: 'week',
        unit: Units.Week.epochMS
      };
    }
    if (grouping.dateType === 'month') {
      return {
        key: `month_${grouping.fieldName}`,
        name: `month_${grouping.fieldName}`,
        label: grouping.label || 'Month',
        type: 'month',
        unit: Units.Month.epochMS
      };
    }
    if (grouping.dateType === 'year') {
      return {
        key: `year_${grouping.fieldName}`,
        name: `year_${grouping.fieldName}`,
        label: grouping.label || 'Year',
        type: 'year',
        unit: Units.Year.epochMS
      };
    }
    if (grouping.dateType === 'timeOfDay') {
      return {
        key: `time_of_day_${grouping.fieldName}`,
        name: `time_of_day_${grouping.fieldName}`,
        label: grouping.label || 'Time of day',
        type: 'timeOfDay',
        unit: Units.TimeOfDay.elapsedSeconds
      };
    }
    if (grouping.dateType === 'hourOfDay') {
      return {
        key: `hour_of_day_${grouping.fieldName}`,
        name: `hour_of_day_${grouping.fieldName}`,
        label: grouping.label || 'Hour of day',
        type: 'hourOfDay',
        unit: Units.HourOfDay.hour
      };
    }
    if (grouping.dateType === 'dayOfWeek') {
      return {
        key: `day_of_week_${grouping.fieldName}`,
        name: `day_of_week_${grouping.fieldName}`,
        label: grouping.label || 'Day of week',
        type: 'dayOfWeek',
        unit: Units.DayOfWeek.day
      };
    }
    if (grouping.dateType === 'monthOfYear') {
      return {
        key: `month_of_year_${grouping.fieldName}`,
        name: `month_of_year_${grouping.fieldName}`,
        label: grouping.label || 'Month of year',
        type: 'monthOfYear',
        unit: Units.MonthOfYear.month
      };
    }
    assertNever(grouping.dateType);
  }
  if (grouping.type === 'category') {
    return {
      key: `category_${grouping.fieldName}`,
      name: `category_${grouping.fieldName}`,
      label: grouping.label || initialField?.label || 'Category',
      type: 'category',
      unit: null,
      meta: (initialField as CategoricalAbstractDataField | null)?.meta ?? { labels: null }
    };
  }
  assertNever(grouping);
};

export const getAggName = (aggField: DataSourceConfigGrouping['aggregateFields'][number]) => {
  const { fieldName, operation } = aggField;
  return `${operation}_${fieldName}`;
};

const getUpdatedGroupingMeta = (operation: AggregateField['operation']) => {
  // Really for multiple aggs, sum and count don't really make sense. We can just bypass them.
  // Also bypass the same operation twice because otherwise, the user would have just aggregated correctly the first time,)
  return (['avg', 'min', 'max'] as const).filter(agg => agg !== operation);
};

const getAggField = (
  initialField: AbstractDataField,
  aggField: DataSourceConfigGrouping['aggregateFields'][number],
  grouping: DataSourceConfigGrouping
): AbstractDataField => {
  if (aggField.enabled === false) throw new Error('Aggregate field is disabled');
  const { operation } = aggField;
  const aggName = getAggName(aggField);
  let groupSuffix = '';
  if (grouping.type === 'dateInterval') {
    if (grouping.dateType === 'hour') groupSuffix = ' for Hour';
    else if (grouping.dateType === 'day') groupSuffix = ' for Day';
    else if (grouping.dateType === 'week') groupSuffix = ' for Week';
    else if (grouping.dateType === 'month') groupSuffix = ' for Month';
    else if (grouping.dateType === 'year') groupSuffix = ' for Year';
    else if (grouping.dateType === 'timeOfDay') groupSuffix = ' for Time of Day';
    else if (grouping.dateType === 'hourOfDay') groupSuffix = ' for Hour of Day';
    else if (grouping.dateType === 'dayOfWeek') groupSuffix = ' for Day of Week';
    else if (grouping.dateType === 'monthOfYear') groupSuffix = ' for Month of Year';
    else assertNever(grouping.dateType);
  }
  const initLabel = capitalize(initialField.label);
  if (operation === 'count') {
    return {
      type: 'number',
      unit: Units.Number.int,
      key: aggName,
      name: aggName,
      label: aggField.label || `Count of ${initLabel}${groupSuffix}`,
      meta: { defaultAggFieldConfig: null }
    };
  }
  if (operation === 'group_concat') {
    return {
      type: 'category',
      unit: null,
      key: aggName,
      name: aggName,
      meta: (initialField as CategoricalAbstractDataField).meta ?? { labels: null },
      label: aggField.label || `Combined ${initLabel}${groupSuffix}`
    };
  }
  let label: string = aggField.label || '';
  if (!label) {
    if (operation === 'avg') {
      label = `Average ${initLabel}${groupSuffix}`;
    } else if (operation === 'sum') {
      label = `Total ${initLabel}${groupSuffix}`;
    } else if (operation === 'min') {
      label = `Minimum ${initLabel}${groupSuffix}`;
    } else if (operation === 'max') {
      label = `Maximum ${initLabel}${groupSuffix}`;
    } else {
      assertNever(operation);
    }
  }
  let meta: any = null;
  if (isNumericAbstractDataField(initialField) && !isDateDataType(initialField.type)) {
    meta = {
      defaultAggFieldConfig: getUpdatedGroupingMeta(operation)
    };
  }
  let type: AbstractDataField['type'];
  let unit: AbstractDataField['unit'];
  if (
    initialField.type === 'number' &&
    initialField.unit === Units.Number.int &&
    operation === 'avg'
  ) {
    type = 'number';
    unit = Units.Number.float;
  } else {
    type = initialField.type;
    unit = initialField.unit;
  }

  return {
    type,
    unit,
    meta,
    key: aggName,
    name: aggName,
    label: label
  } as AbstractDataField;
};

export function getGroupedFields(
  initialFields: AbstractDataField[],
  grouping: DataSourceConfigGrouping
) {
  const fieldsByName = Object.fromEntries(initialFields.map(f => [f.name, f]));

  const groupedFields: AbstractDataField[] = [];
  let groupByField: AbstractDataField | null = null;

  // First, add the group by field, unless its an "all" grouping
  if (grouping.type !== 'all') {
    groupByField = getGroupByField(grouping, fieldsByName[grouping.fieldName]);
    groupedFields.push(groupByField);
  }

  // Then, add the rest of the fields
  for (const aggField of grouping.aggregateFields) {
    if (aggField.enabled === false) continue;
    groupedFields.push(getAggField(fieldsByName[aggField.fieldName], aggField, grouping));
  }
  return { fields: groupedFields, groupByField };
}

// OLD VERSION FOR SQLITE (for reference)
// const getGroupByFieldSelect = (
//   groupByField: AbstractDataField,
//   grouping: Exclude<DataSourceConfigGrouping, AllGrouping>
// ): string => {
//   if (grouping.type === 'category') return `"${grouping.fieldName}" AS "${groupByField.name}"`;
//   if (grouping.type === 'dateInterval') {
//     const dateConversionWrapper = `datetime("${grouping.fieldName}" / 1000, 'unixepoch', 'localtime')`;
//     // Note, we want to do the "start" value by using local time, then adjust back to UTC
//     // Otherwise, we return a value that isn't actually UTC and the timezones get slightly off
//     if (grouping.dateType === 'hour')
//       // There is no start of hour modifier, so go with start of day and add the hours
//       return `(strftime('%s', ${dateConversionWrapper}, 'start of day', 'utc') + strftime('%H', ${dateConversionWrapper}) * 60 * 60) * 1000 AS "${groupByField.name}"`;
//     if (grouping.dateType === 'day')
//       return `strftime('%s', ${dateConversionWrapper}, 'start of day', 'utc') * 1000 AS "${groupByField.name}"`;
//     if (grouping.dateType === 'week')
//       // NOTE: we subtract day days because weekday 0 moves you forward
//       return `strftime('%s', ${dateConversionWrapper}, 'start of day', '-6 days', 'weekday 0', 'utc') * 1000 AS "${groupByField.name}"`;
//     if (grouping.dateType === 'month')
//       return `strftime('%s', ${dateConversionWrapper}, 'start of month', 'utc') * 1000 AS "${groupByField.name}"`;
//     if (grouping.dateType === 'year')
//       return `strftime('%s', ${dateConversionWrapper}, 'start of year', 'utc') * 1000 AS "${groupByField.name}"`;
//     // Now the "OF" types.
//     if (grouping.dateType === 'timeOfDay')
//       // Just hour and minute here
//       return `(strftime('%H', ${dateConversionWrapper}) * 60 * 60) + (strftime('%M', ${dateConversionWrapper}) * 60) AS "${groupByField.name}"`;
//     if (grouping.dateType === 'hourOfDay')
//       return `strftime('%H', ${dateConversionWrapper}) AS "${groupByField.name}"`;
//     if (grouping.dateType === 'dayOfWeek')
//       return `strftime('%w', ${dateConversionWrapper}) AS "${groupByField.name}"`;
//     if (grouping.dateType === 'monthOfYear')
//       // Minus 1 since we do 0-11 for months
//       return `strftime('%m', ${dateConversionWrapper}) - 1 AS "${groupByField.name}"`;
//     assertNever(grouping.dateType);
//   }
//   assertNever(grouping);
// };

const getTimezoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getGroupByFieldSelect = (
  groupByField: AbstractDataField,
  grouping: Exclude<DataSourceConfigGrouping, AllGrouping>
): string => {
  if (grouping.type === 'category') return `"${grouping.fieldName}" AS "${groupByField.name}"`;
  if (grouping.type === 'dateInterval') {
    const dateConversionWrapper = `timezone('${getTimezoneName()}', to_timestamp("${grouping.fieldName}" / 1000))`;
    /**
     * NOTE:
     * An important detail when you do date_trunc is that the timezone is removed. Therefore, we need
     * to cast "back" to the timezone we want after the truncation, hence the timezone wrapper.
     */
    const dateTruncWrapper = (trunc: string) =>
      `timezone('${getTimezoneName()}', date_trunc('${trunc}', ${dateConversionWrapper}))`;
    if (grouping.dateType === 'hour')
      return `EXTRACT(EPOCH FROM ${dateTruncWrapper('hour')}) * 1000 as "${groupByField.name}"`;
    if (grouping.dateType === 'day')
      return `EXTRACT(EPOCH FROM ${dateTruncWrapper('day')}) * 1000 as "${groupByField.name}"`;
    if (grouping.dateType === 'week')
      return `EXTRACT(EPOCH FROM ${dateTruncWrapper('week')}) * 1000 as "${groupByField.name}"`;
    if (grouping.dateType === 'month')
      return `EXTRACT(EPOCH FROM ${dateTruncWrapper('month')}) * 1000 as "${groupByField.name}"`;
    if (grouping.dateType === 'year')
      return `EXTRACT(EPOCH FROM ${dateTruncWrapper('year')}) * 1000 as "${groupByField.name}"`;
    // Now the "OF" types.
    if (grouping.dateType === 'hourOfDay')
      return `EXTRACT(HOUR FROM ${dateConversionWrapper}) AS "${groupByField.name}"`;
    if (grouping.dateType === 'timeOfDay')
      // Both Hour and Minute for this one, in seconds
      return `EXTRACT(HOUR FROM ${dateConversionWrapper}) * 60 * 60 +  EXTRACT(MINUTE FROM ${dateConversionWrapper}) * 60 as "${groupByField.name}"`;
    if (grouping.dateType === 'dayOfWeek')
      return `EXTRACT(DOW FROM ${dateConversionWrapper})::int AS "${groupByField.name}"`;
    if (grouping.dateType === 'monthOfYear')
      // Minus 1 since we do 0-11 for months
      return `EXTRACT(MONTH FROM ${dateConversionWrapper})::int - 1 AS "${groupByField.name}"`;
    assertNever(grouping.dateType);
  }
  assertNever(grouping);
};

export function getGroupingSelects(
  groupByField: AbstractDataField | null,
  grouping: DataSourceConfigGrouping,
  selected: string[] | 'all'
) {
  const selectStatements: string[] = [];
  if (groupByField && grouping.type !== 'all')
    selectStatements.push(getGroupByFieldSelect(groupByField, grouping));
  for (const aggField of grouping.aggregateFields) {
    if (aggField.enabled === false) continue;
    const fieldName = getAggName(aggField);
    if (aggField.operation === 'group_concat') {
      selectStatements.push(`string_agg(distinct "${aggField.fieldName}") AS "${fieldName}"`);
      continue;
    }
    if (selected !== 'all' && !selected.includes(fieldName)) continue;
    if (aggField.operation === 'avg') {
      selectStatements.push(`avg("${aggField.fieldName}")::DOUBLE PRECISION AS "${fieldName}"`);
    } else {
      selectStatements.push(`${aggField.operation}("${aggField.fieldName}") AS "${fieldName}"`);
    }
  }
  return selectStatements;
}
