import convert from 'convert';
import {
  AbstractDataField,
  DataToUnitMap,
  DataUnit,
  FieldType,
  UnitPreferences,
  Units
} from 'daydash-data-structures';

import { assertNever, getCurrentTimezoneOffsetMilliseconds } from 'axil-utils';

const getTargetUnitFromUserPreferences = (init: DataUnit, pref: UnitPreferences): DataUnit => {
  if (pref.temperature === Units.Temperature.C && init === Units.Temperature.F)
    return Units.Temperature.C;
  if (pref.temperature === Units.Temperature.F && init === Units.Temperature.C)
    return Units.Temperature.F;
  if (pref.energy === Units.Energy.kJ && init === Units.Energy.kcal) return Units.Energy.kJ;
  if (pref.energy === Units.Energy.kcal && init === Units.Energy.kJ) return Units.Energy.kcal;

  if (pref.liquid === Units.Liquid.ml && init === Units.Liquid.floz) return Units.Liquid.ml;
  if (pref.liquid === Units.Liquid.floz && init === Units.Liquid.ml) return Units.Liquid.floz;
  if (pref.liquid === Units.Liquid.ml && init === Units.Liquid.gal) return Units.Liquid.L;
  if (pref.liquid === Units.Liquid.floz && init === Units.Liquid.L) return Units.Liquid.gal;

  if (pref.mass === Units.Mass.lb && init === Units.Mass.kg) return Units.Mass.lb;
  if (pref.mass === Units.Mass.kg && init === Units.Mass.lb) return Units.Mass.kg;

  if (pref.distance === Units.Distance.km && init === Units.Distance.mi) return Units.Distance.km;
  if (pref.distance === Units.Distance.mi && init === Units.Distance.km) return Units.Distance.mi;
  if (pref.distance === Units.Distance.km && init === Units.Distance.ft) return Units.Distance.m;
  if (pref.distance === Units.Distance.mi && init === Units.Distance.m) return Units.Distance.ft;
  if (pref.distance === Units.Distance.km && init === Units.Distance.in) return Units.Distance.cm;
  if (pref.distance === Units.Distance.mi && init === Units.Distance.cm) return Units.Distance.in;

  if (pref.distance === Units.Distance.km && init === Units.Velocity.mph) return Units.Velocity.kph;
  if (pref.distance === Units.Distance.mi && init === Units.Velocity.kph) return Units.Velocity.mph;

  return init;
};

/**
 * Yep, this is gnarly, but it works.
 *
 * Here's how
 *
 * - The serial number is the number of days since 12/30/1899 plus a decimal
 *   representing the time of day.
 * - The time of day is the decimal part of the serial number multiplied by the
 *   the seconds in a day (example. 45000.1 -> 0.1 * 60 * 60 * 24 = 8640 seconds since midnight)
 * - We just take the base date of 12/30/1899 (which is a serial number of 0)
 *   and add the number of days to it to get the current date, then the number of seconds
 *   with '%'
 * - Once we have the day time, we just add the time of day to it by extracting the
 *   decimal part and multiplying by the number of seconds in a day (60 * 60 * 24)
 * - Now since the time is in UTC, we need to convert it to the user's timezone
 */
const getSerialNumberConverter = (extracted: string) => {
  return `(
    EXTRACT(EPOCH FROM 
      ('1899-12-30'::date + INTERVAL '1 day' * floor(cast(${extracted} as REAL))) 
    ) + 
    (
      (cast(${extracted} as REAL) - floor(cast(${extracted} as REAL))) * 60 * 60 * 24
    )
  ) * 1000 + ${getCurrentTimezoneOffsetMilliseconds()}`;
};

// Old Sqlite version
// const getSerialNumberConverter = (extracted: string) => {
//   return `(
//     strftime('%s', '1899-12-30', '+' || floor(${extracted}) || ' days') +
//     ((${extracted} - floor(${extracted})) * 60 * 60 * 24)
//   ) * 1000 + ${getCurrentTimezoneOffsetMilliseconds()}`;
// };

export const getConvertedFields = (
  fields: AbstractDataField[],
  preferences: UnitPreferences
): AbstractDataField[] => {
  return fields.map(field => {
    let unit: DataUnit | null;
    if (field.type === 'dateTime') unit = Units.DateTime.epochMS;
    else if (field.type === 'date') unit = Units.Date.epochMS;
    // TODO: Allow for more duration types in the future but for now, just stick with seconds
    else if (field.type === 'duration') unit = Units.Duration.sec;
    else if (field.unit) unit = getTargetUnitFromUserPreferences(field.unit, preferences);
    else unit = null;
    return {
      ...field,
      key: field.name,
      unit
    } as AbstractDataField;
  });
};

const unitMatch = <T extends FieldType, U1 extends DataToUnitMap[T], U2 extends DataToUnitMap[T]>(
  u1: U1,
  u2: U2
) => `${u1}____${u2}` as const;

export const getConvertedSQLVal = (
  extracted: string,
  initialField: AbstractDataField,
  currentField: AbstractDataField
) => {
  if (initialField.type !== currentField.type) throw new Error('Type mismatch!');
  if (initialField.unit === currentField.unit) return extracted; // No-op, bail out
  const type = initialField.type;
  switch (type) {
    case 'dateTime': {
      if (currentField.unit !== Units.DateTime.epochMS)
        throw new Error('Current field unit should always be epochMS');
      switch (initialField.unit) {
        case Units.DateTime.epochSeconds:
          return `${extracted} * 1000 + ${getCurrentTimezoneOffsetMilliseconds()}`;
        case Units.DateTime.epochMS:
          return `${extracted} + ${getCurrentTimezoneOffsetMilliseconds()}`;
        case Units.DateTime.SerialNumber:
          return getSerialNumberConverter(extracted);
        default:
          assertNever(initialField);
      }
      break;
    }
    // The current field type should always be epochMS
    case 'date': {
      if (currentField.unit !== Units.Date.epochMS)
        throw new Error('Current field unit should always be epochMS');
      switch (initialField.unit) {
        case Units.Date.epochSeconds:
          return `${extracted} * 1000`;
        case Units.Date.epochMS:
          return extracted;
        case Units.Date.SerialNumber:
          return getSerialNumberConverter(extracted);
        default:
          assertNever(initialField);
      }
      break;
    }
    case 'hour': // Always ms...
    case 'week':
    case 'month':
    case 'year':
    case 'timeOfDay': // Always elapsed seconds...
    case 'hourOfDay':
    case 'dayOfWeek':
    case 'monthOfYear': {
      return extracted;
    }
    case 'distance': {
      const unitToConvertUnit = {
        [Units.Distance.km]: 'kilometers',
        [Units.Distance.mi]: 'miles',
        [Units.Distance.m]: 'meters',
        [Units.Distance.ft]: 'feet',
        [Units.Distance.cm]: 'centimeters',
        [Units.Distance.in]: 'inches'
      } as const;
      // Since you just do multiplication, you can use convert
      return `${extracted} * ${convert(1, unitToConvertUnit[initialField.unit]).to(
        unitToConvertUnit[currentField.unit as Units.Distance]
      )}`;
    }
    case 'temperature': {
      // You can't rely on a simple conversion factor here and you need an actual formula
      const match = unitMatch(initialField.unit, currentField.unit as Units.Temperature);
      switch (match) {
        case unitMatch(Units.Temperature.C, Units.Temperature.F):
          return `(${extracted} * 9 / 5) + 32`;
        case unitMatch(Units.Temperature.C, Units.Temperature.K):
          return `${extracted} + 273.15`;
        case unitMatch(Units.Temperature.F, Units.Temperature.C):
          return `(${extracted} - 32) * 5 / 9`;
        case unitMatch(Units.Temperature.F, Units.Temperature.K):
          return `((${extracted} - 32) * 5 / 9) + 273.15`;
        case unitMatch(Units.Temperature.K, Units.Temperature.C):
          return `${extracted} - 273.15`;
        case unitMatch(Units.Temperature.K, Units.Temperature.F):
          return `((${extracted} - 273.15) * 9 / 5) + 32`;
        case unitMatch(Units.Temperature.C, Units.Temperature.C):
        case unitMatch(Units.Temperature.F, Units.Temperature.F):
        case unitMatch(Units.Temperature.K, Units.Temperature.K): {
          return extracted;
        }
        default:
          assertNever(match);
      }
      break;
    }
    case 'duration': {
      const unitToConvertUnit = {
        [Units.Duration.sec]: 'seconds',
        [Units.Duration.ms]: 'milliseconds',
        [Units.Duration.min]: 'minutes'
      } as const;
      // Since you just do multiplication, you can use convert
      return `${extracted} * ${convert(1, unitToConvertUnit[initialField.unit]).to(
        unitToConvertUnit[currentField.unit as Units.Duration]
      )}`;
    }
    case 'mass': {
      const unitConversion = {
        [Units.Mass.lb]: 'pounds',
        [Units.Mass.kg]: 'kilogram'
      } as const;
      // Since you just do multiplication, you can use convert
      return `${extracted} * ${convert(1, unitConversion[initialField.unit]).to(
        unitConversion[currentField.unit as Units.Mass]
      )}`;
    }

    case 'energy': {
      const KCAL_TO_KJ = 4.184;
      const KJ_TO_KCAL = 0.2390057361;
      const match = unitMatch(initialField.unit, currentField.unit as Units.Energy);
      switch (match) {
        case unitMatch(Units.Energy.kcal, Units.Energy.kJ):
          return `${extracted} * ${KCAL_TO_KJ}`;
        case unitMatch(Units.Energy.kJ, Units.Energy.kcal):
          return `${extracted} * ${KJ_TO_KCAL}`;
        case unitMatch(Units.Energy.kcal, Units.Energy.kcal):
        case unitMatch(Units.Energy.kJ, Units.Energy.kJ):
          return extracted;
        default:
          assertNever(match);
      }
      break;
    }
    case 'liquid': {
      const unitConversion = {
        [Units.Liquid.ml]: 'milliliter',
        [Units.Liquid.floz]: 'fl oz',
        [Units.Liquid.L]: 'liters',
        [Units.Liquid.gal]: 'gallon'
      } as const;
      // Since you just do multiplication, you can use convert
      return `${extracted} * ${convert(1, unitConversion[initialField.unit]).to(
        unitConversion[currentField.unit as Units.Liquid]
      )}`;
    }
    case 'velocity': {
      const unitConversion = {
        [Units.Velocity.mph]: 'miles',
        [Units.Velocity.kph]: 'kilometers'
      } as const;
      // Since you just do multiplication, you can use convert
      return `${extracted} * ${convert(1, unitConversion[initialField.unit]).to(
        unitConversion[currentField.unit as Units.Velocity]
      )}`;
    }
    case 'currency': // TODO: Maybe consider monetary conversion later
    case 'number':
    case 'string':
    case 'category':
      return extracted; // Should have already been handled when casting. No need to convert
    default:
      assertNever(initialField);
  }
  throw new Error(`Conversion case of ${initialField.unit} to ${currentField.unit} not handled`);
};
