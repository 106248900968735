import { z } from 'zod';
import { Units } from '.';
const unitPreferenceSchema = z.object({
    temperature: z.nativeEnum(Units.Temperature).default(Units.Temperature.F),
    energy: z.nativeEnum(Units.Energy).default(Units.Energy.kcal),
    liquid: z.nativeEnum(Units.Liquid).default(Units.Liquid.floz),
    distance: z.nativeEnum(Units.Distance).default(Units.Distance.mi),
    mass: z.nativeEnum(Units.Mass).default(Units.Mass.lb)
});
export const userPreferenceSchema = z.object({
    v: z.literal(0).default(0),
    // TODO: Try to figure out a way to assert that the z enum has all the types of the preferences enum
    appearance: z.enum(['dark', 'light', 'system']).default('system'),
    units: z
        .object({
        temperature: z.nativeEnum(Units.Temperature).default(Units.Temperature.F),
        energy: z.nativeEnum(Units.Energy).default(Units.Energy.kcal),
        liquid: z.nativeEnum(Units.Liquid).default(Units.Liquid.floz),
        distance: z.nativeEnum(Units.Distance).default(Units.Distance.mi),
        mass: z.nativeEnum(Units.Mass).default(Units.Mass.lb)
    })
        .default(unitPreferenceSchema.parse({}))
});
