import isNumber from 'lodash/isNumber';
export * from './date';
export * from './sorting';
export * from './types';
export function isAxiosError(err) {
    return !!err?.isAxiosError;
}
export const moveItem = (arr, from, to) => {
    const cloned = [...arr];
    cloned.splice(to, 0, cloned.splice(from, 1)[0]);
    return cloned;
};
export const isNotEmpty = (value) => {
    return value !== null && value !== undefined;
};
/**
 * Will take a split array path and join it into a single string for
 * lookups across various tools. Useful for form validation libraries
 *
 * ['name', 1, 'subField] -> 'name[1].subField
 */
export const joinLookupArrayPath = (path) => {
    return path.reduce((joined, part) => {
        if (isNumber(part))
            return joined + `[${part}]`;
        if (!part)
            return joined;
        if (!joined)
            return part;
        return joined + '.' + part;
    }, '');
};
/**
 * Opposite of joinLookupArrayPath
 *
 * NOTE: This currently cannot handle int key lookups
 */
export const splitLookupPath = (path) => {
    const separatorCheck = /(\.|\[|\])/gi;
    const parts = path.trim().split(separatorCheck);
    const splitPath = [];
    for (const part of parts) {
        if (separatorCheck.test(part) || !part)
            continue;
        const parsedNum = parseInt(part, 10);
        if (!Number.isNaN(parsedNum)) {
            splitPath.push(parsedNum);
        }
        else {
            splitPath.push(part);
        }
    }
    return splitPath;
};
// Creates a simple range iterator for use in for...of loops
export function range(start, end, step = 1) {
    return {
        [Symbol.iterator]() {
            return this;
        },
        next() {
            if (start > end) {
                return { done: true, value: end };
            }
            start = start + step;
            return { value: start, done: false };
        }
    };
}
