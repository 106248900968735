/**
 * How to add new data types
 *
 * 1. Add the new data type with units below
 * 2. In daydash-data-structures index.ts, add the new data type to the DataToUnitMap and DataToMetaMap
 * 3. In daydash-units-descs, add the new unit to the fieldTypeToDescs and create a unit desc
 * 4. In the daydash-common-ui conversion module, add conversion and fix any type errors
 * 5. Fix any type errors in daydash-ui
 *
 * If its a type that requires a preference, then that requires more work, but it should be these three steps
 *
 * Note: The reason why daydash-unit-descs is split out and there isn't one single package for units
 * is because unit descs relies on certain browser APIs that are not available in Node.js
 *
 * TODO:
 * - Add support for "time" without a date in case you want to group by time of day
 *    - Example: Trying to see how I do on average at 3pm
 * - Add support for temperature deltas
 */
export var DateTime;
(function (DateTime) {
    DateTime["epochMS"] = "epochMS";
    DateTime["epochSeconds"] = "epochSeconds";
    DateTime["SerialNumber"] = "SerialNumber";
})(DateTime || (DateTime = {}));
// Also acts as the day/date grouping type for date/time fields
export var Date;
(function (Date) {
    Date["epochMS"] = "epochMS";
    Date["epochSeconds"] = "epochSeconds";
    Date["SerialNumber"] = "SerialNumber";
})(Date || (Date = {}));
// Date/Time Grouping types
export var Hour;
(function (Hour) {
    Hour["epochMS"] = "epochMS"; // Represents the start of the hour
})(Hour || (Hour = {}));
export var Week;
(function (Week) {
    Week["epochMS"] = "epochMS"; // Represents the start of the week (TODO: Figure out Mondays and Sundays)
})(Week || (Week = {}));
export var Month;
(function (Month) {
    Month["epochMS"] = "epochMS"; // Represents the start of the month
})(Month || (Month = {}));
export var Year;
(function (Year) {
    Year["epochMS"] = "epochMS"; // Represents the start of the year
})(Year || (Year = {}));
// Grouping date/time "of" types for representing a repeating time period
export var TimeOfDay;
(function (TimeOfDay) {
    TimeOfDay["elapsedSeconds"] = "elapsedSeconds"; // Seconds since 12:00am
})(TimeOfDay || (TimeOfDay = {}));
export var HourOfDay;
(function (HourOfDay) {
    HourOfDay["hour"] = "hour"; // 0-23
})(HourOfDay || (HourOfDay = {}));
export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["day"] = "day"; // 0-6, 0 is Sunday
})(DayOfWeek || (DayOfWeek = {}));
export var MonthOfYear;
(function (MonthOfYear) {
    MonthOfYear["month"] = "month"; // 0-11
})(MonthOfYear || (MonthOfYear = {}));
export var Duration;
(function (Duration) {
    Duration["sec"] = "sec";
    Duration["ms"] = "ms";
    Duration["min"] = "min";
})(Duration || (Duration = {}));
export var Temperature;
(function (Temperature) {
    Temperature["C"] = "C";
    Temperature["F"] = "F";
    Temperature["K"] = "K";
})(Temperature || (Temperature = {}));
export var Energy;
(function (Energy) {
    Energy["kJ"] = "kJ";
    Energy["kcal"] = "kcal";
})(Energy || (Energy = {}));
export var Liquid;
(function (Liquid) {
    Liquid["ml"] = "ml";
    Liquid["floz"] = "floz";
    Liquid["L"] = "L";
    Liquid["gal"] = "gal";
})(Liquid || (Liquid = {}));
export var Distance;
(function (Distance) {
    Distance["km"] = "km";
    Distance["mi"] = "mi";
    Distance["m"] = "m";
    Distance["ft"] = "ft";
    Distance["cm"] = "cm";
    Distance["in"] = "in";
})(Distance || (Distance = {}));
export var Mass;
(function (Mass) {
    Mass["lb"] = "lb";
    Mass["kg"] = "kg";
})(Mass || (Mass = {}));
export var Number;
(function (Number) {
    Number["float"] = "float";
    Number["int"] = "int";
    Number["percentage"] = "percentage";
})(Number || (Number = {}));
export var Velocity;
(function (Velocity) {
    Velocity["kph"] = "kph";
    Velocity["mph"] = "mph";
})(Velocity || (Velocity = {}));
export { CurrencyCode } from './currency';
