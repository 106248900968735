// Put into its own file for circular dependency reasons
export const interpolationOptions = [
    { type: 'basis', label: 'Basic' },
    { type: 'bundle', label: 'Bundle' },
    { type: 'cardinal', label: 'Cardinal' },
    { type: 'catmullRom', label: 'Catmull-Rom' },
    { type: 'linear', label: 'Linear' },
    { type: 'monotoneX', label: 'Monotone X (default)' },
    { type: 'monotoneY', label: 'Monotone Y' },
    { type: 'natural', label: 'Natural' },
    { type: 'radial', label: 'Radial' },
    { type: 'step', label: 'Step' },
    { type: 'stepAfter', label: 'Step After' },
    { type: 'stepBefore', label: 'Step Before' }
];
