import { z } from 'zod';
import { filterSchema, groupingSchema } from '.';
import { interpolationOptions } from './interpolation';
import { isNotEmpty } from 'axil-utils';
const deprecatedDataSourceConfigValue = z.object({
    id: z.string().min(1, 'Data Source Required'),
    sectionId: z.string().min(1, 'Section Required'),
    filters: z.array(filterSchema).optional(),
    grouping: groupingSchema.optional().nullable()
});
const layerConfigVal = z
    .tuple([
    z.object({
        dataSourceId: z.string().optional(),
        sectionId: z.string().min(1, 'Section Required'),
        filters: z.array(filterSchema).optional()
    })
])
    .rest(z.object({
    grouping: groupingSchema,
    filters: z.array(filterSchema).optional()
}));
const dataSourceConfigVal = z.object({ layers: layerConfigVal }).or(
// Transform the old system to the new system
deprecatedDataSourceConfigValue.transform(value => {
    return {
        layers: layerConfigVal.parse([
            { dataSourceId: value.id, sectionId: value.sectionId, filters: value.filters },
            value.grouping ? { grouping: value.grouping } : null
        ].filter(isNotEmpty))
    };
}));
const dataSourceFieldVal = z.object({
    fieldName: z.string().min(1, 'Required'),
    color: z.string().optional()
});
const aggVal = z
    .enum(['avg', 'min', 'max', 'total', 'count'])
    .default('total')
    .optional();
const scaleVal = z.enum(['log', 'linear']).default('linear').optional();
const chartCombinationVal = z.enum(['grouped', 'stacked']).nullish().default(null).optional();
const interpolationVal = z
    .union(interpolationOptions.map(option => z.literal(option.type)))
    .default('monotoneX')
    .optional();
const layoutVal = z
    .object({
    w: z.number(),
    h: z.number(),
    x: z.number(),
    y: z.number()
})
    .optional();
const chartConfigVal = z.object({
    dataSource: dataSourceConfigVal,
    independentAxis: z.object({
        type: z.literal('independent').default('independent'),
        field: dataSourceFieldVal,
        label: z.string().optional()
    }),
    dependentAxis: z.object({
        type: z.literal('dependent').default('dependent'),
        fields: z.array(dataSourceFieldVal).nonempty(),
        label: z.string().optional()
    })
});
// Should match the latest interfaces in packages/data-structures/src/widgets.ts
export const schema = z.discriminatedUnion('type', [
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('LineChart'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        chartConfig: chartConfigVal,
        horizontal: z.boolean().default(false).optional(),
        showDataPoints: z.boolean().default(false).optional(),
        interpolationType: interpolationVal,
        scale: scaleVal
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('AreaChart'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        chartConfig: chartConfigVal,
        showDataPoints: z.boolean().default(false).optional(),
        horizontal: z.boolean().default(false).optional(),
        combination: chartCombinationVal,
        interpolationType: interpolationVal,
        scale: scaleVal
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('BarChart'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        chartConfig: chartConfigVal,
        horizontal: z.boolean().default(false).optional(),
        combination: chartCombinationVal
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('CatBarChart'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        chartConfig: chartConfigVal,
        horizontal: z.boolean().default(true).optional(),
        combination: chartCombinationVal,
        aggType: aggVal,
        order: z
            .object({
            name: z.string(),
            dir: z.enum(['ASC', 'DESC'])
        })
            .optional()
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('DataTable'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        dataSource: dataSourceConfigVal,
        fields: z.array(dataSourceFieldVal).nonempty()
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('SingleValue'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        dataSource: dataSourceConfigVal,
        field: dataSourceFieldVal,
        aggType: aggVal
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('PieChart'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        dataSource: dataSourceConfigVal,
        categoryFields: z.array(dataSourceFieldVal).nonempty(),
        mainField: dataSourceFieldVal,
        aggType: aggVal,
        donut: z.boolean().default(false).optional()
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('SankeyChart'),
        v: z.literal(1).default(1),
        title: z.string().default(''),
        dataSource: dataSourceConfigVal,
        sourceField: dataSourceFieldVal,
        destinationField: dataSourceFieldVal,
        valueField: dataSourceFieldVal,
        align: z.enum(['left', 'right', 'center', 'justify']).default('justify')
    }),
    z.object({
        id: z.string().uuid(),
        layout: layoutVal,
        type: z.literal('Note'),
        v: z.literal(1).default(1),
        value: z.string().default('')
    })
]);
