import { v4 as uuid } from 'uuid';

type Cleanup = () => void;

type RPCResult<R = any, E = any> =
  | {
      id: string;
      success: true;
      result: R;
    }
  | {
      id: string;
      success: false;
      error: E;
    };

type RPCCall<P = any[]> = {
  id: string;
  method: string;
  params: P;
};

export type CommonRPCMessage = RPCResult | RPCCall | 'heartbeat' | 'disconnect';

export const isRPCResult = (message: CommonRPCMessage): message is RPCResult => {
  return typeof message !== 'string' && 'success' in message;
};

export const isRPCCall = (message: CommonRPCMessage): message is RPCCall => {
  return typeof message !== 'string' && 'method' in message;
};

interface MessageTarget {
  postMessage(message: CommonRPCMessage): void;
  addMessageListener: (listener: (message: CommonRPCMessage) => void) => Cleanup;
}

type Cfg = {
  [method: string]: (...params: any[]) => any;
};

export class RPCCancelError extends Error {
  constructor() {
    super('RPC call was canceled');
  }
}

export default class CommonRpc<T extends Cfg, H extends Cfg> {
  public currentCalls = new Map<string, { cancel: () => void }>();

  public cleanupMessageHandler: (() => void) | null = null;

  public disconnected = false;

  private disconnect = () => {
    this.disconnected = true;
    this.onDisconnect?.();
    for (const { cancel } of this.currentCalls.values()) {
      cancel();
    }
  };

  /**
   * Check if the connection is still alive with the target
   */
  initializeConnectionCheck = () => {
    let lastBeat = Date.now();
    const handleMessage = (message: CommonRPCMessage) => {
      if (message === 'heartbeat') {
        const now = Date.now();
        // console.debug('Received heartbeat', now);
        lastBeat = now;
      } else if (message === 'disconnect') {
        this.disconnect();
      }
    };
    this.target.addMessageListener(handleMessage);
    // If we haven't heard back from the other side in 8 seconds, we should disconnect
    const interval = setInterval(() => {
      if (Date.now() - lastBeat > 8000) {
        clearInterval(interval);
        this.disconnect();
      }
    }, 1000);
  };

  /**
   * Send a heartbeat to the target every second in case there is an RPC back
   */
  initializeHeartbeat = () => {
    const interval = setInterval(() => {
      if (this.disconnected) {
        clearInterval(interval);
        return;
      }
    }, 1000);

    if (typeof window !== 'undefined') {
      window.addEventListener('unload', () => {
        // Add a final disconnect in browser environments to clean things up immediately
        this.target.postMessage('disconnect');
      });
    }
  };

  call = async <M extends keyof T, R = ReturnType<T[M]>>(
    method: M,
    ...params: Parameters<T[M]>
  ): Promise<Awaited<R>> => {
    let cleanup: Cleanup | null = null;
    const id = uuid();
    if (typeof method !== 'string') throw new Error('method must be a string');
    const payload: RPCCall = {
      id,
      method,
      params
    };
    try {
      return new Promise<Awaited<R>>((resolve, reject) => {
        const cancel = () => {
          if (cleanup) cleanup();
          this.currentCalls.delete(id);
          reject(new RPCCancelError());
        };
        const respond = (message: RPCResult) => {
          if (!cleanup) throw new Error('cleanup is null');
          this.currentCalls.delete(id);
          cleanup();
          cleanup = null;
          if (message.success) {
            resolve(message.result);
          } else {
            reject(message.error);
          }
        };
        cleanup = this.target.addMessageListener(message => {
          if (!isRPCResult(message)) return;
          if (message?.id !== id) return;
          // console.debug('Received RPC result:', message);
          respond(message);
        });
        this.currentCalls.set(id, { cancel });
        this.target.postMessage(payload);
      });
    } catch (err) {
      if (cleanup) (cleanup as any)(); // TS bug where it think its always null
      throw err;
    }
  };

  addHandlerListeners = () => {
    this.cleanupMessageHandler = this.target.addMessageListener(
      async (message: CommonRPCMessage) => {
        if (!isRPCCall(message)) {
          return;
        }
        // console.debug('Received RPC call:', message);
        const { id, method, params } = message;
        try {
          const result = await this.handlers[method](...params);
          this.target.postMessage({ id, success: true, result });
        } catch (error) {
          this.target.postMessage({ id, success: false, error });
        }
      }
    );
  };

  constructor(
    private target: MessageTarget,
    private handlers: H,
    private onDisconnect?: () => void
  ) {
    this.initializeConnectionCheck();
    this.initializeHeartbeat();
    this.addHandlerListeners();
  }

  static createFromMessagePort<T extends Cfg, H extends Cfg>(
    port: MessagePort,
    handlers: H,
    onDisconnect?: () => void
  ) {
    const target = {
      postMessage: port.postMessage.bind(port),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: MessageEvent) => {
          listener(event.data);
        };
        port.addEventListener('message', handler);
        return () => {
          port.removeEventListener('message', handler);
        };
      }
    };
    return new CommonRpc<T, H>(target, handlers, onDisconnect);
  }

  static createFromBroadcastChannel<T extends Cfg, H extends Cfg>(
    channel: BroadcastChannel,
    handlers: H,
    onDisconnect?: () => void
  ) {
    const target = {
      postMessage: channel.postMessage.bind(channel),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: MessageEvent) => {
          listener(event.data);
        };
        channel.addEventListener('message', handler);
        return () => {
          channel.removeEventListener('message', handler);
        };
      }
    };
    return new CommonRpc<T, H>(target, handlers, onDisconnect);
  }
}
