import type * as Tauri from '@tauri-apps/api';
import { isWebworker } from './utils';

const patchInEnvToServiceURL = (serviceUrl: string) => {
  const initialServiceURL = new URL(serviceUrl);
  const initialServiceURLParts = initialServiceURL.hostname.split('.');
  const location = isWebworker() ? self.location : window.location;
  const appHostNameParts = location.hostname.split('.');
  // It is in a non prod environment. Patch in the env
  if (appHostNameParts.length === 4 && initialServiceURLParts.length === 3) {
    const env = appHostNameParts[1];
    initialServiceURL.hostname = `${initialServiceURLParts[0]}.${env}.${initialServiceURLParts[1]}.${initialServiceURLParts[2]}`;
  }
  return initialServiceURL.toString().replace(/\/$/, ''); // Remove trailing slash
};

export const getServiceURL = () => patchInEnvToServiceURL(import.meta.env.VITE_SERVICE_URL);

export const isTauriEnv = Boolean(
  typeof window !== 'undefined' && typeof (window as any).__TAURI__ !== 'undefined'
);

export const getTauriApi = () => {
  if (isTauriEnv) {
    return (window as any).__TAURI__ as any as unknown as typeof Tauri;
  }
  return null;
};

export const invokeTauri = (cmd: string, payload: Record<string, any>) => {
  const tauri = getTauriApi();
  if (!tauri) throw new Error('Tauri not available');
  // This should be here but its not typed correctly yet, hence why we need to use core
  return (tauri as any).core.invoke(cmd, payload);
};
