import { getServiceURL } from 'src/environment';

export const isTest = () => {
  return Boolean(process.env.NODE_ENV === 'test');
};

export const showPageLoader = () => {
  return document.getElementById('page-loader')?.style.removeProperty('display');
};

export const hidePageLoader = () => {
  return document.getElementById('page-loader')?.style.setProperty('display', 'none');
};

export const isWebworker = () =>
  typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;

export const getLogoSrc = (logo: string) => {
  return `${getServiceURL()}/data-source/logos/${logo}`;
};

export const moveItem = <T>(arr: Array<T>, from: number, to: number) => {
  const cloned = [...arr];
  cloned.splice(to, 0, cloned.splice(from, 1)[0]);
  return cloned;
};

export const isInViewport = (elem: Element) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

// Use negative numbers for scaling down, positive for scaling
export const scaleBoundingBox = (initial: DOMRect, pixels: number): DOMRect => {
  const updated = {
    height: initial.height + pixels * 2,
    width: initial.width + pixels * 2,
    x: initial.x - pixels,
    y: initial.y - pixels,
    left: initial.left - pixels,
    right: initial.right + pixels,
    top: initial.top - pixels,
    bottom: initial.bottom + pixels,
    toJSON: () => updated
  };
  return updated;
};

export const isInBoundingBox = (
  rect: DOMRect,
  { clientX, clientY }: { clientX: number; clientY: number }
) => {
  return (
    clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom
  );
};

export const clearIndexedDB = async (databaseName: string) => {
  const request = indexedDB.deleteDatabase(databaseName);
  return new Promise<void>((resolve, reject) => {
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = () => {
      reject(request.error);
    };
    request.onblocked = () => {
      reject(new Error('Clearing the database is blocked by other open connections.'));
    };
  });
};
